.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: #f4f5fa;
  transition: left 0.3s ease;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(116, 116, 116, 0.25); /* Added light border */
}

.sidebar.open {
  left: 0;
}

.sidebar-content {
  padding: 15px;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
}

.sidebar-menu li {
  margin: 15px 0;
}

.sidebar-menu li a {
  color: #000;
  text-decoration: none;
}

.close-btn {
  background: none;
  border: none;
  color: #000;
  font-size: 24px;
  float: right;
}
