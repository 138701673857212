/* AutoComplete.css */

/* AutoComplete.css */



.p-autocomplete-items {
    padding: 5 !important; /* Remove any padding */
}

.p-autocomplete-item {
    padding-left: 5 !important; /* Ensure items start from the left without padding */
}



