/*
* demo.css
* File include item demo only specific css only
******************************************************************************/


.rotate {
  animation: rotate 0.5s linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card-hover-light:hover{
  background-color: #f0f0f0;
}

.light-style .menu .app-brand.demo {
  height: 64px;
}

.dark-style .menu .app-brand.demo {
  height: 64px;
}

.app-brand-logo.demo svg {
  width: 30px;
  height: 24px;
}

.app-brand-text.demo {
  font-size: 1.25rem;
}

.authentication-wrapper .app-brand-text.demo {
  font-size: 1.5rem;
  text-transform: capitalize;
}
/* ! For .layout-navbar-fixed added fix padding top tpo .layout-page */
.layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page {
  padding-top: 64px !important;
}

/* Navbar page z-index issue solution */
.content-wrapper .navbar {
  z-index: auto;
}

/*
* Content
******************************************************************************/

.demo-blocks > * {
  display: block !important;
}

.demo-inline-spacing > * {
  margin: 1rem 0.375rem 0 0 !important;
}

/* ? .demo-vertical-spacing class is used to have vertical margins between elements. To remove margin-top from the first-child, use .demo-only-element class with .demo-vertical-spacing class. For example, we have used this class in forms-input-groups.html file. */
.demo-vertical-spacing > * {
  margin-top: 1.25rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-lg > * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-lg.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-xl > * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-xl.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.rtl-only {
  display: none !important;
  text-align: left !important;
  direction: ltr !important;
}

[dir='rtl'] .rtl-only {
  display: block !important;
}

/* Dropdown buttons going out of small screens */
@media (max-width: 576px) {
  #dropdown-variation-demo .btn-group .text-truncate {
    width: 300px;
    position: relative;
  }
  #dropdown-variation-demo .btn-group .text-truncate::after {
    position: absolute;
    top: 45%;
    right: 0.65rem;
  }
}
/*
* Layout demo
******************************************************************************/

.layout-demo-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 1.25rem;
}
.layout-demo-placeholder img {
  width: 900px;
}
.layout-demo-info {
  text-align: center;
  margin-top: 1.25rem;
}


.xyz{
  color: aqua;
}

.btn-success {
  background-color: rgb(7, 123, 7);
  border-color: rgb(3, 109, 3);
  color: white;
}

.btn-success:disabled {
  background-color: rgb(127, 240, 127);
  border-color: rgb(127, 240, 127);
  color: white;
}
.fw-bold1 {
  font-weight: bold;
  font-size: 14px; /* Adjust the font size as needed */
}
.text-primary1 {
color: rgb(111, 111, 255);
}
.active1 {
color:   #8c57ff;;
}
.menu-item1 {
  color: grey;
}

.menu-item.active1 {
  color: blue;
}
.custom-red-border {
  border: 2px solid red;
  border-radius: 50px; /* Adjust border-radius as needed */
  color: red;
  background-color: transparent;
}

.even-row {
  background-color: #f9f9f9; /* Light grey color for even rows */
}

.odd-row {
  background-color: #ffffff; /* White color for odd rows */
}
.custom-grey-button {
  color: grey !important;  /* Important is used to override any conflicting styles */
  border: 1px solid grey;
}
.custom-btn-action1{
  width:32px;
  height: 32px;
 
}

.swiper-wrapper {
  display: flex;
  flex-direction: row;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  width: 100%; /* Adjust as needed */
}

.custom-nav-link.active {
  border-bottom: 3px solid #8c57ff; 
  color: #8c57ff !important; /* Overwrite default color */
  font-weight: bolder !important; /* Make active link bold */
}

.custom-nav-link {
  font-weight:normal !important; /* Lighter weight for inactive */

}
.custom-spinner {
  width: 17px;
  height: 17px;
  border-width: 2px; 
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1s linear infinite;
}


.custom-column-border {
  border: 1px solid #ddd;
}

