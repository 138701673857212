

.blinker-circle {
  border-radius: 50%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.blinker-blink {
  border-radius: 50%;
  position: absolute;
  animation: blinker-move 2s infinite;
}

@keyframes blinker-move {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}